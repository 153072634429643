import $ from 'jquery';
import '../../node_modules/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min';
import '../../node_modules/jquery-popup-overlay/jquery.popupoverlay';



$(window).on('load',function() {
  // -- -- -- BEGIN - ADD CUSTOM SCROLLBAR -- -- --
  $('.form__wrapper .form').mCustomScrollbar({
    theme: 'minimal-dark'
  });
  // -- -- -- END - ADD CUSTOM SCROLLBAR -- -- --
});

$(document).ready(function() {


  $('.modal').popup({
    transition: 'all 0.3s',
    outline: true,
    focusdelay: 400,
    vertical: 'top',
    closebutton: true
  });


  // -- -- -- BEGIN -- FORM - INPUT(LABEL) TRANSFORM -- -- --

  var input = $('.form__field');
  var labelForm = $('.form__label') ;
  var email = 'form__field_email';
  var pass = 'form__field_pass';
  var name = 'form__field_name';


  $(input).focus(function() {
    $(this).prev(input).addClass('active');
    if ($(this).hasClass(email)) {
      $(this).prev(labelForm).text('Ваша почта');
    }
    else if ($(this).hasClass(pass)) {
      $(this).prev(labelForm).text('Ваш пароль');
    }
    else if ($(this).hasClass(name)) {
      $(this).prev(labelForm).text('Ваше имя');
    }
  });

  $(input).focusout(function() {
    '' === $(this).val() && $(this).prev(input).removeClass('active');
    if ($(this).hasClass(email) && $(this).prev(labelForm).hasClass('active') === false) {
      $(this).prev(input).text('Введите Вашу почту');
    }
    else if ($(this).hasClass(pass) && $(this).prev(labelForm).hasClass('active') === false) {
      $(this).prev(input).text('Введите Ваш пароль');
    }
    else if ($(this).hasClass(name) && $(this).prev(labelForm).hasClass('active') === false) {
      $(this).prev(input).text('Введите Ваше имя');
    }
  });

  // -- -- -- END -- FORM - INPUT(LABEL) TRANSFORM -- -- --

  // -- -- -- BEGIN -- TABS -- -- --

  $('.form__wrapper .form').not(':first').hide();
  $('.nav .button').click(function(e) {
    e.preventDefault();
    $('.nav .button').removeClass('active').eq($(this).index()).addClass('active');
    $('.form__wrapper .form').hide().eq($(this).index()).fadeIn();
  }).eq(0).addClass('active');
  $('.button-reg').click(function() {
    // e.preventDefault();
    $('.nav .button:nth-child(1)').removeClass('active').eq($(this).index());
    $('.nav .button:nth-child(2)').addClass('active').eq($(this).index());
    $('.form__wrapper .form:nth-child(1)').hide();
    $('.form__wrapper .form:nth-child(2)').show().eq($(this).index()).fadeIn();
  });
  $('.button-aut').click(function() {
    // e.preventDefault();
    $('.nav .button:nth-child(2)').removeClass('active').eq($(this).index());
    $('.nav .button:nth-child(1)').addClass('active').eq($(this).index());
    $('.form__wrapper .form:nth-child(2)').hide();
    $('.form__wrapper .form:nth-child(1)').show().eq($(this).index()).fadeIn();
  });

  // -- -- -- END -- TABS -- -- --
  //-- -- -- ANCHORN BEGIN script-- -- --
  $('.button-aut, .button-reg').on('click', function(event) {
    var target = $(this.getAttribute('href'));

    if (target.length) {
      event.preventDefault();
      $('html, body').stop().animate({
        scrollTop: target.offset().top
      }, 1000);
    }
  });
  //-- -- -- ANCHORN END script-- -- --
  $('input[name="search"]').focus(function() {
    $('.search').addClass('active');
  });
  $('input[name="search"]').focusout(function() {
    '' === $(this).val() && $(this).prev('input[name="search"]').removeClass('active');
    $('.search').removeClass('active');
  });
});
